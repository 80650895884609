import cdiLocationCache from '@/cache/cdiLocation.cache'

export const poStore = {
	state: {
		name: 'po',
		title: 'Purchase Order',
		titlePlural: 'Purchase Orders',
		nav: {
			base: 'po',
			newView: true,
			views: [
				{view: 'details', label: 'Details'}
			]
		},

		search: {
			endPoint: 'pos',
			formFields: {
				company: {
					val: '',
					qsField: '[numbers][p.company_id]',
					label: 'Company',
					component: 'SearchTypeaheadInput',
					cdiType: 'company'
				},
				poNum: {
					val: '',
					qsField: '[numbers][purchase_order_id]',
					label: 'PO #',
					component: 'SearchTextInput',
				},
				vendorInvoice: {
					val: '',
					qsField: '[fstrings][vendor_invoice_num]',
					label: 'Vendor Invoice',
					component: 'SearchTextInput',
				},
				poDateFrom: {
					val: '',
					qsField: '[dates][purchase_order_date_from]',
					label: 'PO Date From',
					component: 'SearchDateInput',
				},
				poDateTo: {
					val: '',
					qsField: '[dates][purchase_order_date_to]',
					label: 'PO Date To',
					component: 'SearchDateInput',
				},
				etaDate: {
					val: '',
					qsField: '[dates][purchase_order_eta_date]',
					label: 'ETA',
					component: 'SearchDateInput',
				},
				purchasedItem: {
					val: '',
					qsField: '[raw_material_id]',
					label: 'Purchased Item',
					component: 'SearchTypeaheadInput',
					cdiType: 'material'
				},
				cdiLocation: {
					val: '',
					qsField: '[numbers][cdi_location_id]',
					label: 'CDI Location',
					component: 'SearchSelectInput',
					options: cdiLocationCache.CDI_LOCATIONS
				},
				deliveryDate: {
					val: '',
					qsField: '[dates][purchase_order_delivery_date]',
					label: 'Delivery Date',
					component: 'SearchDateInput',
					toggleHide: true,
					defaultVal: '',
				},
				openPo: {
					val: '',
					qsField: '[bopen]',
					label: 'Open POs',
					component: 'SearchCheckboxInput',
					toggleHide: true,
					defaultVal: '',
				},
                searchIndex: {val: 0, qsField: '[search_index]'},
			},
			resultFields: {
				purchase_order_id: {
					header: 'PO ID',
					link: 'po',
				},
				company_id: {
					header: 'Company',
					link: 'company',
					textField: 'company_name'
				},
				purchase_order_total: {
					header: 'Amount',
					link: ''
				},
				purchase_order_date: {
					header: 'Date',
					link: ''
				},
				purchase_order_eta_date: {
					header: 'ETA',
					link: ''
				},
				purchase_order_delivery_date: {
					header: 'Date',
					link: ''
				}
			}
		},
		object: {
			fields: [
				{
					name: 'purchase_order_id',
					label: 'Purchase Order ID',
					component: 'DetailsStatic'
				},
				{
					name: 'company_id',
					label: 'Company',
					link: 'company',
					displayField: 'company_name',
					component: 'DetailsLink'
				},
				{
					name: 'cdi_location_id',
					label: 'CDI Location',
					component: 'DetailsSelect',
					options: cdiLocationCache.CDI_LOCATIONS
				},
				{
					name: 'purchase_order_date',
					label: 'PO Date',
					component: 'DetailsDate'
				},
				{
					name: 'vendor_invoice_num',
					label: 'Invoice #',
					component: 'DetailsText'
				},
				{
					name: 'purchase_order_eta_date',
					label: 'ETA Date',
					component: 'DetailsDate'
				},
				{
					name: 'purchase_order_delivery_date',
					label: 'Delivery Date',
					component: 'DetailsDate'
				},
				{
					name: 'tracker_id',
					label: 'Order #',
					component: 'DetailsText'
				},
				{
					name: 'po_quote_file_id',
					label: 'Quote PDF',
					component: 'DetailsFile',
					linkField: 'po_quote_file_id'
				},
				{
					name: 'corporate',
					label: 'Corporate',
					component: 'DetailsCheckbox'
				},
				{
					name: 'ato',
					label: 'ATO',
					component: 'DetailsCheckbox'
				},
				{
					name: 'po_notes',
					label: 'Notes',
					component: 'DetailsTextarea',
				},
				{
					name: 'company_notes',
					label: 'Vendor Notes',
					component: 'DetailsStatic'
				}
			]
		}
	}
}