<template>
    <div>
        <div v-if="id">
            <h1>Purchase Order {{ state.object.data.purchase_order_id }}</h1>
            <div class='spacing_btm'>
                <ProtectedLink class="po_pdf" :linkType="'button'" :classes="'cdi_button lock btn btn-default'" :text="'PDF'" :id="id" :queryString="queryString" :type="'po'" />
                <input class='cdi_button lock btn btn-default' @click='emailPo' type='button' value='Email'>
            </div>
            <Details :data="state.object.data" />
            <h3>Bill Tos</h3>
            <label class="object_detail_select">Bill To</label>
            <select
                class="cdi_select form-control"
                @change="saveBillTo"
                v-model="state.object.data.company_bill_to_id"
            >
                <option value="0">Not Set</option>
                <option  v-for="(item, index) in billTo" v-bind:key="index" :value="item.company_bill_to_id" >{{ item.cdi_address_label }}</option>
            </select>

            <label class="object_detail_select">Bill To CC 1</label>
            <select
                class="cdi_select form-control"
                @change="saveBillTo"
                v-model="state.object.data.company_bill_to_id_cc"
            >
                <option value="0">Not Set</option>
                <option v-for="(item, index) in billTo" v-bind:key="index" :value="item.company_bill_to_id" >{{ item.cdi_address_label }}</option>
            </select>
            <label class="object_detail_select">Bill To CC 2</label>
            <select
                class="cdi_select form-control"
                @change="saveBillTo"
                v-model="state.object.data.company_bill_to_id_cc_2"
            >
                <option value="0">Not Set</option>
                <option  v-for="(item, index) in billTo" v-bind:key="index" :value="item.company_bill_to_id" >{{ item.cdi_address_label }}</option>
            </select>

            <Items :poItems='state.object.data.po_items' :materials='state.object.data.material_lb_options' />
            <History :history='state.object.data.history' />
        </div>

        <New v-else-if="isCurrentView('new')"/>
        
        <Search v-else :showMoreButton='true' />
    </div>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import { poStore } from "@/components/businessObjects/po/Po.store";
import Search from "@/components/businessObjects/utils/Search";
import Details from "@/components/businessObjects/utils/Details";
import New from "@/components/businessObjects/po/New";
import History from "@/components/businessObjects/utils/History";
import Items from "@/components/businessObjects/po/Items";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";

export default {
    name: 'Container',
    data() {
        return {
            state: store.state,
            poState: poStore.state,
        }
    },
    props: ['id'],
    components: {
        History,
        Search,
        Details,
        Items,
        New,
        ProtectedLink
    },
    created() {
        store.initialize(this.poState);
        this.load();
    },
    methods: {
        load: function () {
            if (this.id) {
                store.load(this.id)
                    .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.purchase_order_id}`]));
                }
        },
        isCurrentView: function (view) {
            return view === this.state.currentView;
        },
        emailPo: function() {
            store.api(`email_po`,{'pdf':1});
        },
        saveBillTo: function (){
            let options = {
                'params[company_bill_to_id]': this.state.object.data.company_bill_to_id,
                'params[company_bill_to_id_cc]': this.state.object.data.company_bill_to_id_cc,
                'params[company_bill_to_id_cc_2]': this.state.object.data.company_bill_to_id_cc_2
            };
             store.api('save',options)
        },
    },
    computed: {
        currentView: function () {
            return this.state.currentView;
        },
        queryString: function() {
            const params = helpers.getQSString({ 'params' : this.companyId});
            return `genid=${Math.floor(Math.random()*101)}&${params}`;
        },
        billTo: function (){
            let billTo = this.state.object.data.bill_tos;
            return billTo
        },
    },
    watch: {
        id: function () {
            this.load();
        },
        currentView: function() {
            if(this.currentView == 'search'){
                window.location.reload()
            }
        }
    }
}

</script>
<style>
.po_pdf{
    margin: auto;
    display: block;
    width: fit-content;
    float: left;
}

</style>