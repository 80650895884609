<template>
    <div>
        <h1>Purchase Orders</h1>
        <div class="row">
            <div class="col-sm-6 col-xs-12 form-group">
                <label>Company</label>
                <Typeahead v-bind:ID.sync='companyId' cdi_type='company' />
            </div>
        </div>
        <input type='button' class='cdi_button btn btn-default' @click='addPurchaseOrder' value='Add PO'>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store';
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import appStore from "@/store/App.store";

export default {
    name: 'New',
    components: {
        Typeahead
    },
    data() {
        return {
            state: store.state,
            companyId: 0,
        }
    },
    methods: {
        addPurchaseOrder: function() {
            appStore.errorBox('Not Accepting New POs');
        }
    }
}

</script>