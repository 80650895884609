<template>
    <div>
        <h3>Items</h3>
        <div class="row">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Purchased Item</label>
                <select v-model='newForm.rawMaterialId.val' class="form-control">
                    <option value='0'>None Selected</option>
                    <option v-for='(material, index) in this.materials' v-bind:key='index' :value='material.raw_material_id'>{{ material.raw_material }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>QTY</label>
                <input type='text' class="form-control" v-model='newForm.orderedQty.val'>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Asset</label>
                <Typeahead cdi_type='asset' ref='assetTypeahead' v-bind:ID.sync='newForm.assetId.val' />
            </div>
        </div>
        <input class='cdi_button btn btn-default spacing_btm' @click='newItem' type='button' value='Add Material'>
        <div class="table-responsive">
            <table class='table'>
                <thead>
                    <tr>
                        <th>Purchased Item</th>
                        <th>Unit</th>
                        <th>QTY</th>
                        <th>Rec</th>
                        <th>Price</th>
                        <th>Asset</th>
                        <th>Note</th>
                        <th class="number">Total</th>
                    </tr>
                </thead>
                <tbody v-if='poItems'>
                    <tr class='cdi_item' v-for='(item, index) in poItems' v-bind:key='index'>
                        <td><router-link :to='`/material/${ item.raw_material_id }`' :target="'_blank'" > {{ item.raw_material }}</router-link></td>
                        <td>{{ item.inventory_unit }}</td>
                        <td>
                            <input @keypress.enter="saveItem(item, 'ordered_qty')" v-model='item.ordered_qty' type='text' class="form-control number">
                        </td>
                        <td>
                            <input @keypress.enter="saveItem(item , 'total_received_qty')" v-model='item.total_received_qty' type='text' class="form-control number">
                        </td>
                        <td>
                            <input @keypress.enter="saveItem(item, 'purchase_order_price')" type='input' v-model='item.purchase_order_price' class="form-control number">
                        </td>

                        <td>{{ item.asset_label }}</td>
                        <td>
                            <input @keypress.enter="saveItem(item, 'note')" type='input' v-model='item.note' class="form-control">
                        </td>
                        <td class="number">${{ item.total}}</td>
                    </tr>
                    <tr>
                        <td colspan='7' class="right"><label>Total</label></td>
                        <td class="number">${{ state.object.data.purchase_order_total }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import { store } from "@/store/BusinessObject.store";
import helpers from "@/store/helpers";
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: 'Items',
    components: {Typeahead},
    props: ['poItems', 'materials'],
    data: function() {
        return {
            state: store.state,
            form: {
                orderedQty: {val: '', qsField: '[ordered_qty]'},
                totalReceived: {val: '', qsField: '[total_received_qty]'},
                pristineTotalReceived: {val: '', qsField: '[pristine_total_received_qty]'},
                poPrice: {val: '', qsField: '[purchase_order_price]'},
                note: {val: '', qsField: '[note]'},
                rawMaterialId: {val: '', qsField: '[raw_material_id]'},
                corporate: {val: '', qsField:'[corporate'},
                ato: {val: '', qsField:'[ato]'}
            },
            newForm: {
                rawMaterialId: { val: 0, qsField: '[raw_material_id]'},
                orderedQty: {val: '', qsField: '[ordered_qty]'},
                assetId: {val: '', qsField: '[asset_id]'},
            }
        }
    },
    methods: {
        setForm: function(params = false) {
            this.form.orderedQty.val             = (params) ? params.ordered_qty : '';
            this.form.totalReceived.val          = (params) ? params.total_received_qty : '';
            this.form.pristineTotalReceived.val  = (params) ? params.pristine_total_received_qty : '';
            this.form.poPrice.val                = (params) ? params.purchase_order_price : '';
            this.form.note.val                   = (params) ? params.note : '';
            this.form.rawMaterialId.val          = (params) ? params.raw_material_id : '';
        },
        saveItem: function(item) {
            this.setForm(item);
            store.apiWithObjectLoad('save_purchase_order_item', helpers.getQSPairs(this.form));
            this.setForm();
        },
        newItem: function() {
            store.apiWithObjectLoad('add_item', helpers.getQSPairs(this.newForm));
            this.newForm.rawMaterialId.val = 0;
            this.newForm.orderedQty.val= '';
            this.newForm.assetId.val = 0;
            this.$refs.assetTypeahead.$data.object = '';
        }
    }
}

</script>